body {
  font-family: "Poppins", sans-serif !important;
}

.App {
  font-family: "Poppins", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
option {
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 320px) and (max-width: 576px) {
}
@media (min-width: 576px) and (max-width: 768px) {
}
@media (min-width: 768px) and (max-width: 1200px) {
  .page-wrapper .responsive-tab {
    width: calc(100% - 300px) !important;
    margin-left: auto;
    position: relative;
    min-height: calc(100vh - 60px);
    padding: 0 8px 52px 8px;
    margin-top: 60px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  html{
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  html{
    font-size: 11px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  html{
    font-size: 11px;
  }
}
.page-wrapper .responsive-tab {
  width: calc(100% - 300px) !important;
  margin-left: auto;
  position: relative;
  min-height: calc(100vh - 60px);
  padding: 0 8px 52px 8px;
  margin-top: 60px;
}

.enlarge-menu .purchase-asidebar {
  min-width: 300px !important;
}

.purchase-asidebar {
  min-width: 300px !important;
  max-width: 300px !important;
}

.historicCard p{
width: 10rem;
}
.historicCard span {
  margin-right: .8rem;
}

.historicCard h5{
 width: 60%;
}


