.cursor-pointer {
  cursor: pointer;
}

.page-wrapper {
  position: relative;
}
.page-wrapper .pfp {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  height: 100%;
  z-index: 1;
  bottom: 4rem;
}
.page-wrapper .pfp img {
  height: 15rem;
  border-radius: 50%;
}

.department__card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 1rem;
  border-radius: 0.6rem;
  transition: 0.2s ease-in-out;
}
.department__card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.department__card .depart_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.department__card .depart_info i {
  font-size: 1.4rem;
  cursor: pointer;
}
.department__card .department_description h6 {
  min-height: 4rem;
  max-height: 4rem;
}
.department__card .multiple_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.department__card .multiple_user .img__ img {
  margin-left: -1rem;
}
.department__card .multiple_user .img__ p {
  background-color: rgba(253, 60, 151, 0.15) !important;
  color: #fd3c97;
  padding: 0.5rem 0.6rem;
  margin-bottom: 0;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.1rem;
}
.department__card .multiple_user_1 {
  display: flex;
  align-items: center;
}
.department__card .multiple_user_1 .titl__ {
  font-weight: 600;
}
.department__card .multiple_user_1 a {
  font-size: 1rem;
  font-weight: 500;
  color: #d03438;
}
.department__card .multiple_user_1 .link-arrow span {
  display: inline-block;
  transition: 0.1s ease-in;
}
.department__card .multiple_user_1 .link-arrow:hover span {
  transform: translateX(50%);
}

.department__card_detail {
  padding: 1rem;
  border-radius: 0.6rem;
}
.department__card_detail ul {
  background: none !important;
}
.department__card_detail ul .nav-link {
  padding: 0.5rem 3rem;
  border: 1px solid #d03438;
  margin-left: 0.6rem;
  color: #d03438;
}

.add-button,
.remove-button {
  background-color: transparent;
  border: 0;
  font-size: 2rem;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}
.add-button:hover,
.remove-button:hover {
  color: #d03438;
}

.remove-button-disabled {
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  font-size: 2rem;
  font-weight: 500;
  color: gray;
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.department-form input,
.department-form textarea {
  border: 1px solid lightgray;
}

.detail-table th,
.detail-table td {
  font-size: 12px;
}

.clear .profile-pic {
  position: relative;
  display: inline-block;
}
.clear .profile-pic .overlay_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 2s ease;
}
.clear .profile-pic .overlay_1:hover {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}
.clear .profile-pic:hover .edit {
  display: block;
}
.clear .edit {
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  left: 45%;
  top: 37%;
  display: none;
  transition: 2s ease;
  font-size: 1.5rem;
}
.clear .edit a {
  color: white;
  background: rgba(0, 0, 0, 0.77);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.btn-department__ button {
  padding: 0.4rem 1rem;
  background-color: #d03438;
  border: 1px solid #d03438;
  color: white;
  margin-left: 0.5rem;
}

.department_description p {
  margin: 0;
}

.recharts-surface {
  max-width: 100%;
  width: 100%;
}

.maximum-panding {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-bottom: 2.8rem;
}
.maximum-panding h4 {
  font-size: 1.1rem;
  color: #d03438;
  font-weight: 600;
}

.project__img img {
  max-width: 20rem;
  max-height: 10rem;
}

.kanban-board .kanban-col .kanban-main-card {
  background: #eef0f2;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  z-index: 0;
}

.menu-content .inactive-link {
  color: #9da2af;
}
.menu-content .inactive-link:hover {
  color: white !important;
}
.menu-content .active {
  color: white !important;
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
}

.table-border-radius {
  border-radius: 0.7rem;
  overflow: hidden;
}
.table-border-radius .accordion-item .accordion-header .accordion-button {
  font-size: 1.2rem;
  font-weight: 600;
}
.table-border-radius .accordion-item .accordion-collapse .accordion-body .table {
  margin-bottom: 0;
}
.table-border-radius .accordion-button:not(.collapsed) {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
}
.table-border-radius .table-responsive .table {
  margin-bottom: 0;
}
.table-border-radius table tr td p {
  color: #3a3a3a;
  font-style: normal;
}

.card-radius {
  border-radius: 1.2rem;
}

.bg-table-black {
  background-color: #0f172a;
}

.dashboard-text {
  color: #3a3a3a;
  font-style: normal;
}

/* Add this CSS to your stylesheet or inline styles */
.dashboard-table table {
  table-layout: fixed;
}

.dashboard-table th,
.dashboard-table td {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.dashboard-table th,
.dashboard-table td {
  width: 9.5rem; /* 100% / 16 columns */
  padding: 0.8rem;
  text-align: center;
}

.border .th-min-long,
.td-min-long {
  width: 18rem;
  padding: 0.8rem;
  text-align: center;
}

.border .th-long,
.td-long {
  width: 25rem;
  padding: 0.8rem;
  text-align: center;
}

.border .th-small,
.td-small {
  width: 6rem;
  padding: 0.8rem;
  text-align: center;
}

.border .th-mid,
.td-mid {
  width: 9rem;
}

.nav-item:has(.inactive-link.active) {
  border-radius: var(--8, 8px);
  background: var(--white-100, #fff);
}
.nav-item:has(.inactive-link.active) a {
  color: #d03438 !important;
}

.material-inspection-table h5 {
  width: 13rem;
  font-weight: 500;
  font-size: 1rem;
}

.tender-list-table p {
  min-width: 9rem;
  max-width: -moz-max-content;
  max-width: max-content;
  margin: 0;
}
.tender-list-table h6 {
  font-size: 1.1rem;
  margin: 0.5rem 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordianHeader .accordion-button {
  font-size: 1rem;
  font-weight: 600;
}

.project-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: box-shadow 0.2s ease-in-out;
}
.project-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.categorty-name {
  border: 1px solid gray;
  display: grid;
  place-content: center;
  height: 3rem;
  width: 3rem;
}

.user-card {
  transition: 0.2s ease-in-out;
  border: 1px solid lightgray;
}
.user-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.base-Popper-root {
  width: 30% !important;
}/*# sourceMappingURL=style.css.map */